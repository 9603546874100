import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
const { cookies } = useCookies();






const Messages = {
    state: {
        snack_message:{
            status:'info',
            text:'',
            timeout: 1500,
            key:0
          },
          top_message:null
    },
    getters: {
        SNACK_MESSAGE: state =>{
            return state.snack_message
          },
        TOP_MESSAGE: state=>{
            return state.top_message
        }
    },
    mutations: {
        SET_SNACK_MESSAGE:(state, data) =>{
            state.snack_message['text'] = data['text']
            if(data['status'] != undefined){
                state.snack_message['status'] = data['status']
            }
            var key = JSON.parse(JSON.stringify(state.snack_message['key']))
            state.snack_message['key']  = key + 1
        },
        SET_TOP_MESSAGE:(state, data)=>{
            state.top_message = data
        }
        
        

        },
    actions: {
        CLEAR_SNACK_MESSAGE:(state,id)=>{
            var message = {text:undefined, status: 'info'}
            state.commit("SET_SNACK_MESSAGE", message)
          },
        SEND_SNACK_MESSAGE:(state,message)=>{
              state.commit("SET_SNACK_MESSAGE",message)
          },
        CLEAR_TOP_MESSAGE:(state)=>{
            var message = null
            state.commit("SET_TOP_MESSAGE", message)
          },
        SEND_TOP_MESSAGE:(state,message)=>{
              state.commit("SET_TOP_MESSAGE",message)
          }
       
    },
  
    modules: {
        
    }
  }

  

  export default {
      Messages: Messages
  }