import axios from 'axios';

var cancleTokens = {}

const Docs = {
    state: {
        f_recipients:[]
        
    },
    getters: {
        F_RECIPIESNTS: state =>{
            return state.f_recipients
        }
    },
    mutations: {
        SET_F_RECIPIENTS: (state, payload) =>{
            state.f_recipients = payload
        }

        },
    actions: {
        GET_DOCS_LIST:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/docs/v1/docs_list",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //
        ADD_DOC:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/docs/v1/docs/add", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
                
            })},
        // Получить получателей для полей
        GET_F_RECIPIENTS:(state, payload) =>{
            if(cancleTokens['GET_F_RECIPIENTS']){
                cancleTokens['GET_F_RECIPIENTS'].cancel();
            }
            const CancelToken = axios.CancelToken;
            cancleTokens['GET_F_RECIPIENTS'] = CancelToken.source();
            
            var obj = {"page":1,"itemsPerPage":5 }
            obj['search'] = payload
            var params = {
                cancelToken: cancleTokens['GET_F_RECIPIENTS'].token,
                responseType: "json",
                params:obj
            }
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/docs/v1/recipients", params).then(
                    response => {
                        state.commit('SET_F_RECIPIENTS', response.data.result.items)
                        resolve(response);
                    },
                    error =>{console.log(error)});
                })
            
        }
        
    },
  
    modules: {

    }
  }

  

  export default {
      Docs: Docs
  }