import { createStore } from 'vuex'

// import Users from './modules/users/user'
import Animals from './modules/animals'
import IDB from './modules/idb'
import ECP from './modules/ecp'
import Users from './modules/users'
import Messages from './modules/messages'
import Docs from './modules/docs'
import Files from './modules/files'


 const store = createStore({
  state: {
    
    

  },
  getters: {
      
  },
  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    // user: Users.Users,
    animals: Animals.Animals,
    IDB: IDB.IDB,
    ECP: ECP.ECP,
    Users: Users.Users,
    Messages: Messages.Messages,
    Docs: Docs.Docs,
    Files: Files.Files
  }
})



export default store