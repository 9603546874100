import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
const { cookies } = useCookies();



// В будущих версиях необходимо вынести пути Axios в константу,
// для быстрого редактирования путей 
// 
// Так же вывести обработку ошибок reject во внешний вызов

const Authorization = {
    state: {
        accessToken:'', //Access Token
        refreshToken:'',//Refresh Token
        ecpToken:'',//Ecp Token
    },
    getters: {
        // Геттер для получения Refresh Token
        REFRESH_TOKEN: state =>{
            return state.refreshToken},
        // Геттер для получения Access Token
        ACCESS_TOKEN: state =>{
            return state.accessToken
        },
        // Получить авторизован ли пользователь
        AUTH_STATUS: state =>{
            if(state.refreshToken != undefined){
                return true
            }
            return false
            },
        // Получить ЭЦП токен
        ECP_TOKEN: state =>{
            return state.ecpToken
            },
    },
    mutations: {
        //Refresh Token
        SET_REFRESH_TOKEN: (state, token) =>{
            state.refreshToken = token},
        //Access Token
        SET_ACCESS_TOKEN:(state, token)=>{
            state.accessToken = token },
        //Установить ECP Token
        SET_AUTH_ECP_TOKEN: (state, token) =>{
            state.ecpToken = token},
        // 
        },
    actions: {
        // Устанавливаем Refresh Token
        SET_REFRESH_TOKEN: async (state, payload=null) =>{
            //Если в значении передано значение "clear" очищаем refreshToken
            if(payload == "clear"){
                Preferences.clear('refreshToken')
                state.commit('SET_REFRESH_TOKEN', '');
            }else{
                // если значение передано
                if(payload != null){
                    // сохраняем значение в localstorage
                    await Preferences.set({
                        key: 'refreshToken',
                        value: JSON.stringify(payload)})
                        state.commit('SET_REFRESH_TOKEN', payload);
                }else{
                    //Если не передано, получаем данные из localStorage
                    const ret = await Preferences.get({ key: 'refreshToken' });
                    const refreshToken = JSON.parse(ret.value);
                    state.commit('SET_REFRESH_TOKEN', refreshToken);
                }
            }},
        // Устанавливаем Access Token
        SET_ACCESS_TOKEN: async (state, payload=null) =>{
            //Если в значении передано значение "clear" очищаем accessToken
            if(payload == "clear"){
                cookies.remove("accessToken")
                state.commit('SET_ACCESS_TOKEN', '');
            }else{
                // если значение передано
                if(payload != null){
                    // сохраняем значение в cookie
                        cookies.set("accessToken",payload)
                        state.commit('SET_ACCESS_TOKEN', payload);
                }else{
                    //Если не передано, получаем данные из localStorage
                    var accessToken = cookies.get('accessToken')
                    state.commit('SET_ACCESS_TOKEN', accessToken);
                }
            }},
        // Авторизация пользователя по логину и паролю
        AUTH_USER: (state,payload)=>{
            return new Promise((resolve, reject) =>  {
                // Отправляем запрос на авторизацию пользователя
              axios.post(state.getters.SERVER_ADRESS+"/users/v1/auth", {
                'login': payload.login,
                'password': sha256(payload.password)
              }).then(async response => {
                    // В случае успеха
                    var result = response.data.result
                    console.log('Установка токена')
                    await state.dispatch('SET_REFRESH_TOKEN',result['refreshToken'] )
                    console.log('Установка завершена')
                    await state.dispatch('SET_ACCESS_TOKEN',result['accessToken'] )

                    resolve(response);
                },error =>{
                    // В случае ошибки
                    reject(error)
                });
              
            })},
        // Авторизация пользователя по ЭЦП
        AUTH_ECP: (state, payload)=>{
            return new Promise((resolve, reject) => {
                // Отправляем запрос на авторизацию по ЭЦП
                axios.post(state.getters.SERVER_ADRESS+"/users/v1/auth/ecp", payload)
              .then(response => {
                    // В случае успеха
                    var result = response.data.result
                    state.dispatch('SET_REFRESH_TOKEN',result['refreshToken'] )
                    state.dispatch('SET_ACCESS_TOKEN',result['accessToken'] )
                    resolve(response);
                },error =>{
                    // В случае ошибки
                    reject(error)
                });
                
            })},
        // Установка Токена подписи ЭЦП
        SET_AUTH_ECP_TOKEN: async (state, payload) =>{
            return new Promise((resolve, reject) => {
                // Запрос токена на сервере
                axios.get(state.getters.SERVER_ADRESS+"/users/v1/auth/ecp/token").then(response => {
                    var result = response.data.result
                    // Оборачиваем данные в "data" и сохраняем
                    state.commit('SET_AUTH_ECP_TOKEN', {data:result})
                    resolve(response);
                },error =>{
                    reject(error)
                 });
                
              })},
        // Получить новый Access Token
        GET_NEW_ACCESS_TOKEN:(state, payload)=>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/v1/access-token", {
                  'refresh_token': payload
                }).then(async response => {
                    var result = response.data.result
                    // В случае успеха
                    console.log('Установка токена')
                    await state.dispatch('SET_REFRESH_TOKEN',result['refreshToken'] )
                    console.log('Установка завершена')
                    await state.dispatch('SET_ACCESS_TOKEN',result['accessToken'] )
                    resolve(response);
                
                },error =>{
                    reject(error)
                  });
                
            })},
        // Выход пользователя
        LOGOUT_USER: async(state, payload) =>{
            return new Promise((resolve, reject) => {
                var refreshToken = state.getters.REFRESH_TOKEN
                axios.post(state.getters.SERVER_ADRESS+"/users/v1/logout",{'refresh_token':refreshToken})
              .then(response => {console.log(response);resolve(response);},error =>{console.log(error)})
              .finally(()=>{
                    state.dispatch('SET_ACCESS_TOKEN', 'clear' )
                    state.dispatch('SET_REFRESH_TOKEN', 'clear' )
                    location.reload();
                  });
                
              })},
        //Регистрация пользователя
        REGISTRATION_USER: (state, payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/v1/reg",payload)
              .then(response => {resolve(response);},error =>{reject(error)})  
              })
        },
        AUTH_CMS: (state, payload)=>{
            return new Promise((resolve, reject) => {
                // Отправляем запрос на авторизацию по ЭЦП
                axios.post(state.getters.SERVER_ADRESS+"/users/v1/auth/cms", payload)
              .then(response => {
                    // В случае успеха
                    var result = response.data.result
                    state.dispatch('SET_REFRESH_TOKEN',result['refreshToken'] )
                    state.dispatch('SET_ACCESS_TOKEN',result['accessToken'] )
                    resolve(response);
                },error =>{
                    // В случае ошибки
                    reject(error)
                });
                
            })},
        // Установка Токена подписи ЭЦП
        },
  
    modules: {
    }
  }

  

  export default {
        Authorization:Authorization
  }