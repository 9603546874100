const Files = {
    state: {

    },
    getters: {
        FILES_PATH:(state,rootState) =>{
            return rootState.SERVER_ADRESS +"/files?id="
        } 
    },
    mutations: {
    },
    actions: {

    },
    
  
    modules: {
    }
  }
  export default {
      Files: Files
  }