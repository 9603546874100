<template>
    <v-row no-gutters style="height:100vh" class="mx-16 justify-center">
        <v-col cols="12" class="d-flex justify-center">
         <v-img max-width="400px" style="pointer-events: none" src="/logo.svg"></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center text-h4">
            <v-img style="height:200px;pointer-events: none"  src="./animals_loading.gif"></v-img>
        </v-col>
    </v-row>
    <v-dialog v-model="select_workplace_dialog">
      <v-select :items="this.$store.getters.WORKPLACES" item-value="id" item-title="staff" :return-object="false" v-model="workplace_id"></v-select>
    </v-dialog>
    
</template>
<script>
import { createSimpleExpression } from '@vue/compiler-core';

export default {
  name: 'LoadingView',
  data:()=>({
    select_workplace_dialog:false,
    workplace_id:'',
  }),
  watch:{
    workplace_id(val){
      this.$store.dispatch('SET_ACTIVE_WORKPLACE', val)
    }
  },
  computed:{
    session_id(){
      return this.$store.getters.SESSION_ID;
    },

  },
  created:async function(){
      console.log('Инициация начата')
      console.log("Получаем аццесс и рефреш токены из хранилищ")
      await this.$store.dispatch('INIT_LANG')
      // Инициируем класс USERS
      await this.$store.dispatch('INIT_USERS')
      await this.$store.dispatch('INIT_ANIMALS')
      
      
      
          this.$router.replace("/main")
      
          

            
          //   //Если пользователь не авторизован открываем главную страницу
          // }else{
          //   this.$router.replace("/main")
          // }
        
  
        
      
      
  },
  computed:{
    
  },
  methods:{
    async init(step=null, val){
      //Инициирует запуск фйункций если параметр не задан то данный шаг
      if(step == null){
       
        
      }else if(step == 'workplace_set'){
       

      }
    }
  }
}
</script>