import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
const { cookies } = useCookies();



const Initialization = {
    state: {
        workplaces:[], //Список рабочих мест
        active_workplace:'', //Активное рабочее место
        lang:'EN',
        user_info:{} //Информация о пользователе
    },
    getters: {
        //Получить активное рабочее место
        ACTIVE_WORKPLACE: state=>{
            return state.active_workplace
            },
        //Список рабочих мест
        WORKPLACES: state=>{
            return state.workplaces
            },
        //Существует ли данные пользователя
        HAVE_USER_INFO: state=>{
            return state.user_info.status == true
            },
        //получить язык
        LANG: state=>{
            return state.lang
        },
        //Получить информацию о пользователе
        USER_INFO: state =>{
            return state.user_info
            },
        //Полное имя пользователя
        USER_FULLNAME: state=>{
            return state.lastname + ' ' + state.firstname
            },
        // 
        USER_CHECK_PERMISSION: state => id =>{
            if(state.user_info['permissions'] == undefined){
                return false
            }else{
                return state.user_info['permissions'][0].findIndex(elem =>{return elem == id}) > -1
            }
            
        },
        USER_PERMISSIONS: state =>{
                return state.user_info['permissions']

            
        }
        },
    mutations: {
        SET_LANG:(state,lang)=>{
            state.lang = lang
        },
        //Установка рабочих мест
        SET_WORKPLACES: (state, data)=>{
            state.workplaces = data
            },
        //Установка активного рабочего места
        SET_ACTIVE_WORKPLACE: (state, data)=>{
            state.active_workplace = data
            },
        //Установка информации о пользователе
        SET_USER_INFO:(state, data)=>{
            data['status'] = true
            state.user_info = data
            },
    },
    actions: {
        INIT_LANG: async (state)=>{
            const LANG =  (await Preferences.get({ key: 'lang' })).value;
            console.log(LANG)
            if(LANG == undefined){
                await Preferences.set({
                    key: 'lang',
                    value: state.getters.LANG})
            }else{
                var lang = LANG.toUpperCase()
                state.commit('SET_LANG',lang)
            }
            
        },
        SET_LANG: async (state, paylaod)=>{
            var lang = paylaod.toUpperCase()
            state.commit('SET_LANG',lang)
            await Preferences.set({
                key: 'lang',
                value: state.getters.LANG})
        },
        //Установка рабочих мест
        SET_WORKPLACES: async (state, payload) =>{
            return new Promise((resolve, reject) => {
                //Запрос к серверу на получение списка рабочих мест
               axios.get(state.getters.SERVER_ADRESS+"/users/v1/workplaces",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                   
                    //В случае успеха сохраняем рабочие места
                    var result = response.data.result
                    state.commit('SET_WORKPLACES',result)
                    resolve(response)
                },error =>{
                    reject(error)
                })    
            })
            },
        // Устанавливаем активное рабочее место
        SET_ACTIVE_WORKPLACE: async (state, payload=null)=>{
            //Если в параметре передано clear очищаем рабочее место
            if(payload == 'clear'){
                Preferences.clear('workplace_id')
                state.commit('SET_ACTIVE_WORKPLACE','')
            }
            // Если переедаваемые данные не равны нулю
            else if(payload != null){
                // Устанавливаем активное рабочее место из данных
                await Preferences.set({
                    key: 'workplace_id',
                    value: JSON.stringify(payload)})
                state.commit('SET_ACTIVE_WORKPLACE',payload)
            }
            // Если данных нет то получаем из localStorage
            else{
                const ret = await Preferences.get({ key: 'workplace_id' });
                const workplace_id = JSON.parse(ret.value);
                state.commit('SET_ACTIVE_WORKPLACE',workplace_id)
            }
            },
        // Получить основные данные пользователя
        GET_USER_BASE_INFO: (state, payload)=>{
            return new Promise((resolve, reject) => {
                axios.get(state.getters.SERVER_ADRESS+"/users/v1/baseinfo",
                {
                    responseType: "json",
                    params:{'workplace_id': state.getters.ACTIVE_WORKPLACE}
                }
                ).then(response => {
                    var result = response.data.result
                    state.commit('SET_USER_INFO', result)
                  resolve(response);
                },error =>{
                    reject(error)
                 });
                
              })
            }
       
    },
  
    modules: {
    }
  }

  

  export default {
        Initialization:Initialization
  }